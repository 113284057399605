import * as React from 'react'

const DateLine = ({author, authorLink, date}) => {
    return (
        <p className='review-date-line'>
            {
                author && <a href={authorLink} className='text-decoration-none me-1'>{author}</a>
            }
            { date && <span>&#8212;</span>}
            <span className='fs-sm ms-1'>{date}</span>
        </p>
    )
}

export default DateLine;