import * as React from 'react'
import {Link, useStaticQuery, graphql} from 'gatsby'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import DateLine from './dateLine.js'
import Helmet from 'react-helmet'

const Layout = ({
                  pageTitle,
                  pageDate,
                  pageAuthor,
                  pageAuthorLink,
                  pageSummary,
                  hero_image = null,
                  hero_image_alt = null,
                  hero_image_credit_link = null,
                  hero_image_credit_text = null,
                  children
                }) => {

  const data = useStaticQuery(graphql`
    query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `)

  hero_image = hero_image ? getImage(hero_image) : null

  return (
    <>
      <title>{pageTitle} | {data.site.siteMetadata.title}</title>

      <nav className='navbar navbar-expand-md navbar-dark bg-dark'>
        <div className='container'>
          <Link className='navbar-brand' to='/'>Chase Reviews</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                  aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className='collapse navbar-collapse' id="navbarSupportedContent">
            <ul className='navbar-nav'>
              <li className='nav-item'>
                <Link className='nav-link' to="/reviews">
                  Feature and TV Reviews
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to="/about">
                  About
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className={'container-fluid'}>
        <div className={'row'}>
          <div className={'col-lg-3 col-md-12'}>
            {/*<div className={'ad-holder-test text-center mx-auto'}>*/}
            {/*  Ad*/}
            {/*</div>*/}
          </div>
          <div className={'col-lg-6 offset-lg-0 col-md-8 offset-md-2 mt-lg-0 my-5'}>

            {/*MAIN COLUMN*/}
            <div className={'container'}>
              <div className={'row'}>
                <header className={'review-header'}>
                  <h1>{pageTitle}</h1>
                  {(pageAuthor || pageDate) && <DateLine
                    author={pageAuthor}
                    authorLink={pageAuthorLink}
                    date={pageDate}
                  />}
                  <p className='summary text-muted'>{pageSummary}</p>
                </header>
              </div>
              <div className={'row'}>
                <div>
                  <div className='hero_image'>
                    {
                      hero_image && <GatsbyImage
                        image={hero_image}
                        alt={hero_image_alt}
                      />
                    }
                    {
                      hero_image && hero_image_credit_text && <p className='fs-sm text-center'>
                        Photo Credit:{" "}
                        <a href={hero_image_credit_link}>
                          {hero_image_credit_text}
                        </a>
                      </p>
                    }
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <main>
                  {children}
                </main>
              </div>
            </div>
            {/* END MAIN COLUMN */}

          </div>
          <div className={'col-lg-3 col-md-12'}>
            {/*<div className={'ad-holder-test text-center mx-auto'}>*/}
            {/*  Ad*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      <footer className={'p-5 mt-5 bg-dark text-light'}>
        {/*    TODO: add IMDB, Twitter*/}

        <div className={"container"}>
          <p> test text in the footer </p>
        </div>
      </footer>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
                integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
                crossorigin="anonymous"></script>
        <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css" rel="stylesheet"/>
      </Helmet>
    </>
  )
}

export default Layout